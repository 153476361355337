import { Container, Row, Col } from "react-bootstrap";
import Facebook from "../assets/images/facebook.png";
import Instagram from "../assets/images/insta.png";
import logo from "../assets/images/logo-footer.png";
import EDHEC from "../assets/images/Footer/EDHEC-logo.png";
import ThemeProvider from "react-bootstrap/ThemeProvider";

const CustomBreakpoints = {
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
};

const Footer = () => {
  return (
    <footer className="footer">
      <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
        <Container>
          <Row>
            <Col
              md={12}
              className="d-flex flex-column align-items-center text-center"
            >
              <h1 className="follow-us">NOUS SUIVRE</h1>
            </Col>
            <Col md={12} className="">
              <Row className="justify-content-center mt-5">
                <Col
                  md={3}
                  xs={4}
                  className="d-flex flex-column align-items-end text-center"
                >
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Brasserie.EDHEC.393">
                    <img className="groupe-52-icon" alt="" src={Facebook} />
                  </a>
                </Col>
                <Col
                  md={3}
                  xs={4}
                  className="d-flex flex-column align-items-start text-center"
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/monarte.event"
                  >
                    <img className="groupe-51-icon" alt="" src={Instagram} />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Section avec le logo EDHEC au-dessus de la ligne */}
          <Row className="align-items-center pb-3">
            <Col lg={12} className="text-end">
              <img src={EDHEC} alt="EDHEC" className="edhec-logo" />
            </Col>
          </Row>
          <Row className="pb-5">
            <Col lg={12} md={12} xs={12}>
              <hr />
            </Col>
          </Row>
          <Row className="align-items-center pb-5 info">
            <Col lg={3} md={3} xs={12} className="adresse">
              {/* <h3 className="contact-sstitre">ADRESSE</h3> */}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://maps.app.goo.gl/jhfcuGB7zZe9ZmKs6"
              >
                <span className="place-de-lglise1">
                  393 Prom. des Anglais,{" "}
                </span>
                <span className="place-de-lglise1">06200 Nice</span>
              </a>
            </Col>
            <Col lg={4} md={4} xs={12} className="support">
              {/* <h3 className="contact-sstitre">SUPPORT</h3> */}
              <div>
                <a href="tel:+33629175383">
                  <span className="contact-info">T / 04 93 18 78 97</span>
                </a>
                <a href="mailto:monarte.events@gmail.com">
                  <span className="contact-info">
                    E / labrasseriedeledhecnice@gmail.com
                  </span>
                </a>
              </div>
            </Col>
            <Col md={3} lg={2} className="desktop"></Col>
            <Col md={3} lg={3} xs={12} className="d-flex flex-column text-end">
              <a href="/#">
                <img src={logo} alt="logo 3 singes" />
              </a>
            </Col>
          </Row>
          <Row className="pb-5">
            <Col className="d-flex justify-content-center">
              <span className="pq-copyright">
                {/* Copyright&nbsp;
                  <script>
									  document.write(new Date().getFullYear())
								  </script> */}
                {/* 2024&nbsp;Monkeys - Tous droits réservés. -  */}
                Made with&nbsp;💙&nbsp;by&nbsp;
                <a target="_blank" href="https://maelcorp.com" rel="noreferrer">
                  MaelCorp.
                </a>
              </span>
            </Col>
          </Row>
        </Container>
      </ThemeProvider>
    </footer>
  );
};

export default Footer;
